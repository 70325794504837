body {
  background: gray;
}

.mycontent {
    height: 100vh;
}
  
.title {
    margin-top: 10px;
    margin-bottom: 20px;
}
  
.toolbar {
    margin-bottom: 20px;
}
  
.btnBlock {
    padding: 20px 0;
}
  
.mybtn {
    margin: 5px;
}

.mybtn img {
    height: 20px;
}

.mytag {
    background-color: rgb(214, 207, 207);
    padding: 2px;
    margin-right: 3px;
}

.mytag img {
    max-width: 15px;
    max-width: 15px;
}

.mytag button {
    padding: 2px;
    height: 25px;
}
/* common */

.mycontainer {
    background: #dce0f0;
    margin: 30px 0;
    padding: 30px;
    border: 3px solid #ccd3eb;
    border-radius: 20px;
}

.block_back {
    margin: 5px 0;
}

/* items */

.cardsBlock {
    margin-top: 20px;
    padding: 10px 0;
}

.itemCard {
    margin: 5px;
    padding: 10px;
    max-width: 400px;
    flex-grow: 1;
}

.posterCard img{
    max-width: 200px;
    max-height: 320px;
    border-radius: 5px;
}
  
/* collection */

.collection_header{
    padding-bottom: 15px;
}

.itemsBlock {
    padding: 5px;
    border-top: 2px solid #ccd3eb;
}